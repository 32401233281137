import * as Sentry from '@sentry/remix';
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';

Sentry.init({
    enabled: import.meta.env.PROD,
    dsn: 'https://16c2be4a44458aa4af1317e7c5bafbc8@o207571.ingest.us.sentry.io/6364632',
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    tunnel: `https://dating.studio/catch`,

    integrations: [
        Sentry.browserTracingIntegration({
            useEffect,
            useLocation,
            useMatches,
        }),
        Sentry.replayIntegration(),
    ],
});

startTransition(() => {
    hydrateRoot(
        document,
        <StrictMode>
            <RemixBrowser />
        </StrictMode>,
    );
});
